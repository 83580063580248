import React, { useState } from 'react';
import LogoPart from "../assets/images/Icon/LogoPart";
import IconLogo from "../assets/images/Icon/IconLogo";
import Button from "./Button";
import Menu from "../assets/images/Icon/Menu";
import Close from '../assets/images/Icon/Close';
import { Link } from 'react-router-dom'; 

function Header() {
  const [isActive, setIsActive] = useState(false);

  const toggleClass = () => {
    setIsActive(!isActive);
  };

  return ( 
    <header>
      <div className="container">
      <div className={`nav ${isActive ? 'activeNavCel' : ''}`}>
  
        <div className="menu" onClick={toggleClass}>
          <Menu />
        </div>
            <div className='HeaderNav'>
                <h4>Menú</h4>
                <Close className="close" onClick={toggleClass} />
            </div>
          <ul className='celNavText'> 
            <li>
              <a href="/#hero">Inicio</a>
            </li>
            <li>
              <a href="/#planes">Planes</a>
            </li>
            <li>
              <a href="/blog">Blog</a>
            </li>
        
            <li>
              <a href="/portafolio">Portafolio</a>
            </li>
            <li>
              <a href="/#trabajo">¿Cómo trabajamos?</a>
            </li>
            <li>
              <Link to="/ingreso">
              
              <Button>Ingresar</Button>
              </Link>
            </li>
          </ul>
          <ul className='pcNavText pcLeft'> 
            <li>
              <a href="/#hero">Inicio</a>
            </li>
            <li>
            <a href="/blog">Blog</a>
            </li>
            <li>
              <a href="/#planes">Planes</a>
            </li>
          </ul>

            <div className='logo'>

                <IconLogo  />
            </div>

          <ul className='pcNavText pcRiht'>
            <li>
            <a href="/portafolio">Portafolio</a>
            </li>
            <li>
              <a href="/#trabajo">¿Cómo trabajamos?</a>
            </li>
            <li>
              <Link to="/ingreso">
              <Button>Ingresar</Button>
              </Link>
            </li>
          </ul>
          <Link to="/ingreso" className='celNavTextBtn'>
              
              <Button>Ingresar</Button>
          </Link>
        </div>
      </div>
    </header>
  );
}

export default Header;
