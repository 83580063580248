import React from "react";
import { CalendarMonth } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';

function Blog() {
  const navigate = useNavigate();
  
  const articles = [
      {
        id: 1,
        title: "Introducción a React",
        subtitle: "Aprende a construir aplicaciones web con React",
        image: "https://picsum.photos/200/300",
        author: "Juan Pérez",
        date: "2023-06-12",
        content: "Este artículo te introducirá al mundo de React, una biblioteca de JavaScript para construir interfaces de usuario.",
        category: "Desarrollo web",
        tags: ["React", "JavaScript", "Frontend"],
        destacado: 1,
      },
      {
        id: 2,
        title: "Cómo crear una API RESTful con Node.js",
        subtitle: "Aprende a construir una API utilizando Node.js y Express",
        image: "https://picsum.photos/200/301",
        author: "María García",
        date: "2023-07-05",
        content: "En este tutorial, aprenderás a configurar un servidor con Node.js y crear tu primera API RESTful.",
        category: "Desarrollo backend",
        tags: ["Node.js", "API", "Backend"],
        destacado: 0,
      },
      {
        id: 3,
        title: "Guía de Flexbox en CSS",
        subtitle: "Diseño avanzado de layout con Flexbox",
        image: "https://picsum.photos/200/302",
        author: "Andrés Martínez",
        date: "2023-04-25",
        content: "Flexbox es una herramienta poderosa para organizar el layout de tu sitio web. Aquí aprenderás a dominarlo.",
        category: "Desarrollo frontend",
        tags: ["CSS", "Flexbox", "Diseño web"],
        destacado: 0,
      },
      {
        id: 4,
        title: "10 trucos para mejorar la seguridad en tu aplicación web",
        subtitle: "Protege tu web contra ataques comunes",
        image: "https://picsum.photos/200/303",
        author: "Laura Rodríguez",
        date: "2023-02-11",
        content: "Este artículo te ayudará a proteger tu aplicación web de ataques comunes como SQL Injection y Cross-Site Scripting (XSS).",
        category: "Seguridad",
        tags: ["Seguridad", "Aplicaciones web", "XSS"],
        destacado: 0,
      },
      {
        id: 5,
        title: "Desarrollo de apps móviles con React Native",
        subtitle: "Crea aplicaciones móviles multiplataforma",
        image: "https://picsum.photos/200/304",
        author: "Sofía Torres",
        date: "2023-05-18",
        content: "React Native te permite crear aplicaciones móviles utilizando JavaScript y React. Aprende cómo hacerlo en este artículo.",
        category: "Desarrollo móvil",
        tags: ["React Native", "Móvil", "JavaScript"],
        destacado: 0,
      },
      {
        id: 6,
        title: "Introducción a GraphQL",
        subtitle: "Una alternativa a REST para APIs",
        image: "https://picsum.photos/200/305",
        author: "Pedro Sánchez",
        date: "2023-01-22",
        content: "GraphQL es una alternativa flexible a las APIs REST. En este artículo, aprenderás sus fundamentos.",
        category: "Desarrollo backend",
        tags: ["GraphQL", "API", "Backend"],
        destacado: 0,
      },
      {
        id: 7,
        title: "Desarrollo full stack con MERN",
        subtitle: "Construye aplicaciones completas con MongoDB, Express, React y Node.js",
        image: "https://picsum.photos/200/306",
        author: "Ana López",
        date: "2023-03-14",
        content: "El stack MERN es una poderosa combinación para desarrollar aplicaciones full stack. Descubre cómo implementarlo.",
        category: "Desarrollo full stack",
        tags: ["MERN", "Full stack", "JavaScript"],
        destacado: 0,
      },
      {
        id: 8,
        title: "Mejores prácticas de accesibilidad web",
        subtitle: "Cómo hacer que tu web sea inclusiva para todos",
        image: "https://picsum.photos/200/307",
        author: "Carlos Ruiz",
        date: "2023-08-01",
        content: "Una web accesible es una web para todos. Aprende las mejores prácticas para mejorar la accesibilidad de tu sitio.",
        category: "Desarrollo web",
        tags: ["Accesibilidad", "Diseño web", "Frontend"],
        destacado: 0,
      },
      {
        id: 9,
        title: "Implementación de autenticación con JWT en Node.js",
        subtitle: "Seguridad para APIs utilizando JSON Web Tokens",
        image: "https://picsum.photos/200/308",
        author: "Elena Martínez",
        date: "2023-04-07",
        content: "En este artículo aprenderás a implementar autenticación segura en tu API utilizando JWT en Node.js.",
        category: "Seguridad",
        tags: ["Node.js", "JWT", "API"],
        destacado: 0,
      },
      {
        id: 10,
        title: "Introducción a TypeScript para desarrolladores JavaScript",
        subtitle: "Aprende las ventajas de usar TypeScript en tus proyectos",
        image: "https://picsum.photos/200/309",
        author: "Roberto Fernández",
        date: "2023-09-10",
        content: "TypeScript es un superconjunto de JavaScript que añade tipado estático. Descubre por qué deberías utilizarlo.",
        category: "Desarrollo web",
        tags: ["TypeScript", "JavaScript", "Frontend"],
        destacado: 0,
      },
    ];
  
    const handleClick = (id) => {
      navigate(`/blog/${id}`); // Navegar a la página de detalles con el ID
    };
      

  return (
    <>
      {/* BANNER HEADER  */}
      <section className="banner">
        <div className="bg-banner">
          <div className="container">
            <h1>Blog</h1>
            <p>Expora todos nuestros artiuclos </p>
          </div>
        </div>
      </section>

      {/* LISTA DE ARTICULOS */}
      <section className="listArticle">
        <div className="container">
          <h2>Lista de artículos</h2> 
                <div className="grid-3">
                {articles.map(article => ( 
                    <div className="box" key={article.id} onClick={() => handleClick(article.id)}>
                    <div className="img">
                        <img src={article.image} alt={article.title} />
                    </div>
                        <div className="fecha">
                            <CalendarMonth />
                            <span>{article.date}</span>
                        </div>
                        <h3>{article.title}</h3>
                        <p>{article.content}</p>
                            <h6 className="categoria">
                                <span>Categoria</span> {article.category}
                            </h6>
                    </div>
                ))}
                </div>
        </div>
      </section>
    </>
  );
}

export default Blog;
