import React, { useState, useEffect, useRef } from "react";
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import Button from "../Button";
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const projects = [
  
  { name: "garbage warriors", 
    galeria: [
      'https://www.sitcod.com/images-proyectos/garbage-warriors1.jpg',
      'https://www.sitcod.com/images-proyectos/garbage-warriors2.jpg'
    ],
    color: [
      "#64B647",
      "#C2DA2C",
      "#182822",
      "#7E7E7E",
      "#F7F9F8",
    ],
    typography: [
      "Títulos: urbanist",
      "Textos: urbanist"
    ],
    type: "Landig page",
    url: 'https://garbage-warriors.com'
  },

{ name: "Rancho caprino el picaflor", 
    galeria: [
      'https://www.sitcod.com/images-proyectos/Ranchocaprinoelpicaflor1.jpg',
      'https://www.sitcod.com/images-proyectos/Ranchocaprinoelpicaflor2.jpg'
    ],
    color: [
      "#F68F4A",
      "#352B29",
      "#182822",
      "#FFFFFF",
    ],
    typography: [
      "Títulos: Inter",
      "Textos: Inter"
    ],
    type: "Landig page",
    url: 'https://Ranchocaprinoelpicaflor.com'
  },

{ name: "Constructura CSR sas", 
    galeria: [
      'https://www.sitcod.com/images-proyectos/constructuraCSRsas1.jpg',
      'https://www.sitcod.com/images-proyectos/constructuraCSRsas2.jpg',
      'https://www.sitcod.com/images-proyectos/constructuraCSRsas3.jpg',
      'https://www.sitcod.com/images-proyectos/constructuraCSRsas4.jpg',
      'https://www.sitcod.com/images-proyectos/constructuraCSRsas5.jpg',
      'https://www.sitcod.com/images-proyectos/constructuraCSRsas6.jpg',
      'https://www.sitcod.com/images-proyectos/constructuraCSRsas7.jpg',
      'https://www.sitcod.com/images-proyectos/constructuraCSRsas8.jpg'
    ],
    color: [
      "#0283B3",
      "#B3C100",
      "#000000",
      "#EEEEEE",
    ],
    typography: [
      "Títulos: Montserrat classic",
      "Textos: Montserrat"
    ],
    type: "Página web",
    url: 'http://constructoracsrsas.com/'
  },

  { name: "Diapublicidad", 
    galeria: [
      'https://www.sitcod.com/images-proyectos/diapublicidad1.jpg',
      'https://www.sitcod.com/images-proyectos/diapublicidad2.jpg',
      'https://www.sitcod.com/images-proyectos/diapublicidad3.jpg',
      'https://www.sitcod.com/images-proyectos/diapublicidad4.jpg',
      'https://www.sitcod.com/images-proyectos/diapublicidad5.jpg',
      'https://www.sitcod.com/images-proyectos/diapublicidad6.jpg'
    ],
    color: [
      "#2957A4",
      "#EC6035",
      "#272324",
      "#F6F6F6",
    ],
    typography: [
      "Títulos: Inter",
      "Textos: Montserrat"
    ],
    type: "Página web",
    url: 'https://diapublicidad.com.co/'
  },

  { name: "Café Lupin", 
    galeria: [
      'https://www.sitcod.com/images-proyectos/cafelupin1.jpg',
      'https://www.sitcod.com/images-proyectos/cafelupin2.jpg',
      'https://www.sitcod.com/images-proyectos/cafelupin3.jpg',
      'https://www.sitcod.com/images-proyectos/cafelupin4.jpg'
    ],
    color: [
      "#719F87",
      "#C76C61",
      "#F09F54",
      "#401E1F",
    ],
    typography: [
      "Títulos: Montserrat",
      "Textos: poppins"
    ],
    type: "Página web",
    url: 'https://cafelupin.com/'
  },

    { name: "elverbocc", 
    galeria: [
      'https://www.sitcod.com/images-proyectos/elverbocc1.jpg',
      'https://www.sitcod.com/images-proyectos/elverbocc2.jpg',
      'https://www.sitcod.com/images-proyectos/elverbocc3.jpg',
      'https://www.sitcod.com/images-proyectos/elverbocc4.jpg',
      'https://www.sitcod.com/images-proyectos/elverbocc5.jpg',
      'https://www.sitcod.com/images-proyectos/elverbocc6.jpg'
    ],
    color: [
      "#000000",
      "#31827C",
      "#C19745",
      "#B53B2E",
    ],
    typography: [
      "Títulos: Poppins",
      "Textos: Jost"
    ],
    type: "Página web",
    url: 'https://elverbocc.com/'
  },


    { name: "Urólogo Dr. DiegoVasquez", 
    galeria: [
      'https://www.sitcod.com/images-proyectos/UrologoDrDiegoVasquez1.jpg',
      'https://www.sitcod.com/images-proyectos/UrologoDrDiegoVasquez2.jpg',
      'https://www.sitcod.com/images-proyectos/UrologoDrDiegoVasquez3.jpg',
      'https://www.sitcod.com/images-proyectos/UrologoDrDiegoVasquez4.jpg',
      'https://www.sitcod.com/images-proyectos/UrologoDrDiegoVasquez5.jpg',
      'https://www.sitcod.com/images-proyectos/UrologoDrDiegoVasquez6.jpg',
      'https://www.sitcod.com/images-proyectos/UrologoDrDiegoVasquez7.jpg'

    ],
    color: [
      "#27477D",
      "#07CCEC",
      "#151515",
      "#F6F6F6",
      "#888C92",
    ],
    typography: [
      "Títulos: Roboto",
      "Textos: Rubik"
    ],
    type: "Página web",
    url: 'https://urologodiegovasquez.co/'
  },

      { name: "Dr. Jorge alejandro soto", 
    galeria: [
      'https://www.sitcod.com/images-proyectos/drjorgealejandrosoto1.jpg',
      'https://www.sitcod.com/images-proyectos/drjorgealejandrosoto2.jpg',
      'https://www.sitcod.com/images-proyectos/drjorgealejandrosoto3.jpg',
      'https://www.sitcod.com/images-proyectos/drjorgealejandrosoto4.jpg',
      'https://www.sitcod.com/images-proyectos/drjorgealejandrosoto5.jpg'
    ],
    color: [
      "#0A3380",
      "#24ADE3",
      "#6E7379",
      "#000000",
    ],
    typography: [
      "Títulos: Jost",
      "Textos: Mulish"
    ],
    type: "Página web",
    url: 'https://drjorgealejandrosoto.co/'
  },

     { name: "Conselgica", 
    galeria: [
      'https://www.sitcod.com/images-proyectos/Conselgica1.jpg',
      'https://www.sitcod.com/images-proyectos/Conselgica2.jpg',
      'https://www.sitcod.com/images-proyectos/Conselgica3.jpg',
      'https://www.sitcod.com/images-proyectos/Conselgica4.jpg',
      'https://www.sitcod.com/images-proyectos/Conselgica5.jpg',
      'https://www.sitcod.com/images-proyectos/Conselgica6.jpg',
      'https://www.sitcod.com/images-proyectos/Conselgica7.jpg'

    ],
    color: [
      "#18182B",
      "#407FBB",
      "#7E7E7E",
      "#F6F6F6",
      "#C6C6C6",
    ],
    typography: [
      "Títulos: Source Sans",
      "Textos: Source Sans"
    ],
    type: "Página web",
    url: 'https://conselgica.com/'
  },

     { name: "Coldima", 
    galeria: [
      'https://www.sitcod.com/images-proyectos/Coldima1.jpg',
      'https://www.sitcod.com/images-proyectos/Coldima2.jpg',
      'https://www.sitcod.com/images-proyectos/Coldima3.jpg',
      'https://www.sitcod.com/images-proyectos/Coldima4.jpg',
      'https://www.sitcod.com/images-proyectos/Coldima5.jpg'
    ],
    color: [
      "#024ED5",
      "#FB6A09",
      "#1D9DE8",
      "#1E1E2A",
    ],
    typography: [
      "Títulos: Montserrat",
      "Textos: Montserrat"
    ],
    type: "Página web",
    url: 'http://www.coldima.com/'
  },

     { name: "Americana de cortes y dobleces LTDA", 
    galeria: [
      'https://www.sitcod.com/images-proyectos/Americanadecortesydobleces1.jpg',
      'https://www.sitcod.com/images-proyectos/Americanadecortesydobleces2.jpg',
      'https://www.sitcod.com/images-proyectos/Americanadecortesydobleces3.jpg',
      'https://www.sitcod.com/images-proyectos/Americanadecortesydobleces4.jpg',
      'https://www.sitcod.com/images-proyectos/Americanadecortesydobleces5.jpg',
      'https://www.sitcod.com/images-proyectos/Americanadecortesydobleces6.jpg'
    ],
    color: [
      "#EC3237",
      "#0098D9",
      "#373334",
      "#727F84",
      "#D9D9D9",
    ],
    typography: [
      "Títulos: catamarán",
      "Textos: catamarán"
    ],
    type: "Página web",
    url: 'https://americanadecortesydobleces.com/'
  },

     { name: "Motopro Market", 
    galeria: [
      'https://www.sitcod.com/images-proyectos/Motopromarket1.jpg',
      'https://www.sitcod.com/images-proyectos/Motopromarket2.jpg',
      'https://www.sitcod.com/images-proyectos/Motopromarket3.jpg',
      'https://www.sitcod.com/images-proyectos/Motopromarket4.jpg',
      'https://www.sitcod.com/images-proyectos/Motopromarket5.jpg',
      'https://www.sitcod.com/images-proyectos/Motopromarket6.jpg'
    ],
    color: [
      "#151515",
      "#ED3237",
      "#18E50E",
      "#888C92",
      "#D9D9D9",
    ],
    typography: [
      "Títulos: Roboto",
      "Textos: Roboto"
    ],
    type: "Tienda tipo WhatsApp",
    url: 'https://motopromarket.com/'
  },

       { name: "House off place", 
    galeria: [
      'https://www.sitcod.com/images-proyectos/houseoffplace1.jpg',
      'https://www.sitcod.com/images-proyectos/houseoffplace2.jpg',
      'https://www.sitcod.com/images-proyectos/houseoffplace3.jpg'
    ],
    color: [
      "#E9F0F3",
      "#80A6AD",
      "#FBD1C1",
      "#292929",
      "#777777",
    ],
    typography: [
      "Títulos: Josefin Sans",
      "Textos: Open Sans"
    ],
    type: "Tienda tipo WhatsApp",
    url: 'https://houseoffplace.com/'
  },

     { name: "Brizga", 
    galeria: [
      'https://www.sitcod.com/images-proyectos/Brizga1.jpg',
      'https://www.sitcod.com/images-proyectos/Brizga2.jpg',
      'https://www.sitcod.com/images-proyectos/Brizga3.jpg',
      'https://www.sitcod.com/images-proyectos/Brizga4.jpg',
      'https://www.sitcod.com/images-proyectos/Brizga5.jpg',
      'https://www.sitcod.com/images-proyectos/Brizga6.jpg',
      'https://www.sitcod.com/images-proyectos/Brizga7.jpg'
    ],
    color: [
      "#7FB438",
      "#00B3C5",
      "#0071B4",
      "#201F1F",
      "#4B4B4B",
    ],
    typography: [
      "Títulos: Inter",
      "Textos: Montserrat"
    ],
    type: "Tienda online completa",
    url: 'http://brizgacorp.com/'
  },


{ name: "Fitfox", 
    galeria: [
      'https://www.sitcod.com/images-proyectos/Fitfox1.jpg',
      'https://www.sitcod.com/images-proyectos/Fitfox2.jpg',
      'https://www.sitcod.com/images-proyectos/Fitfox3.jpg',
      'https://www.sitcod.com/images-proyectos/Fitfox4.jpg',
      'https://www.sitcod.com/images-proyectos/Fitfox5.jpg',
      'https://www.sitcod.com/images-proyectos/Fitfox6.jpg',
      'https://www.sitcod.com/images-proyectos/Fitfox7.jpg',
      'https://www.sitcod.com/images-proyectos/Fitfox8.jpg',
      'https://www.sitcod.com/images-proyectos/Fitfox9.jpg',
      'https://www.sitcod.com/images-proyectos/Fitfox10.jpg'
    ],
    color: [
      "#F6881F",
      "#EC4E24",
      "#E6E7E8",
      "#58595B",
      "#231F20",
      "#2F4795",
    ],
    typography: [
      "Títulos: Reckoner",
      "Textos: poppins"
    ],
    type: "Tienda online completa",
    url: 'https://fitfox-shop.com/'
  },


    { name: "kyqamhuysqa", 
    galeria: [
      'https://www.sitcod.com/images-proyectos/kyqamhuysqa1.jpg',
      'https://www.sitcod.com/images-proyectos/kyqamhuysqa2.jpg',
      'https://www.sitcod.com/images-proyectos/kyqamhuysqa3.jpg',
      'https://www.sitcod.com/images-proyectos/kyqamhuysqa4.jpg',
      'https://www.sitcod.com/images-proyectos/kyqamhuysqa5.jpg'
    ],
    color: [
      "#FCD117",
      "#242424",
      "#FF8A00",
      "#37281A",
    ],
    typography: [
      "Títulos: catamarán",
      "Textos: Montserrat"
    ],
    type: "Tienda online tipo reservas completa",
    url: 'https://kyqamhuysqa.com/'
  },

      { name: "Mercacentro sas", 
    galeria: [
      'https://www.sitcod.com/images-proyectos/Mercacentrosas1.jpg',
      'https://www.sitcod.com/images-proyectos/Mercacentrosas2.jpg',
      'https://www.sitcod.com/images-proyectos/Mercacentrosas3.jpg',
      'https://www.sitcod.com/images-proyectos/Mercacentrosas4.jpg',
      'https://www.sitcod.com/images-proyectos/Mercacentrosas5.jpg',
      'https://www.sitcod.com/images-proyectos/Mercacentrosas6.jpg',
      'https://www.sitcod.com/images-proyectos/Mercacentrosas7.jpg',
      'https://www.sitcod.com/images-proyectos/Mercacentrosas8.jpg',
      'https://www.sitcod.com/images-proyectos/Mercacentrosas9.jpg',
      'https://www.sitcod.com/images-proyectos/Mercacentrosas10.jpg',
      'https://www.sitcod.com/images-proyectos/Mercacentrosas11.jpg',
      'https://www.sitcod.com/images-proyectos/Mercacentrosas12.jpg',
      'https://www.sitcod.com/images-proyectos/Mercacentrosas13.jpg',
      'https://www.sitcod.com/images-proyectos/Mercacentrosas14.jpg',
      'https://www.sitcod.com/images-proyectos/Mercacentrosas15.jpg',
      'https://www.sitcod.com/images-proyectos/Mercacentrosas16.jpg',
      'https://www.sitcod.com/images-proyectos/Mercacentrosas17.jpg',
      'https://www.sitcod.com/images-proyectos/Mercacentrosas18.jpg',
      'https://www.sitcod.com/images-proyectos/Mercacentrosas19.jpg',
      'https://www.sitcod.com/images-proyectos/Mercacentrosas20.jpg',
      'https://www.sitcod.com/images-proyectos/Mercacentrosas21.jpg',
      'https://www.sitcod.com/images-proyectos/Mercacentrosas22.jpg',
      'https://www.sitcod.com/images-proyectos/Mercacentrosas23.jpg',
      'https://www.sitcod.com/images-proyectos/Mercacentrosas24.jpg'
    ],
    color: [
      "#00AEEE",
      "#2E3091",
      "#FEC73B",
      "#F1F3F6",
      "#252525",
    ],
    typography: [
      "Títulos: Poppins",
      "Textos: Helvetica Neue"
    ],
    type: "Tienda online avanzada",
    url: 'http://mercacentrosas.com/'
  },
];

function Portafolio() {
  const [activeProject, setActiveProject] = useState(0);
  const [isPortfolioInView, setIsPortfolioInView] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const portfolioRef = useRef(null);

  useEffect(() => {
    const handleScroll = (event) => {
      if (!isPortfolioInView) return;

      const delta = event.deltaY;

      if (delta > 0 && activeProject < projects.length - 1) {
        setActiveProject((prev) => prev + 1);
        event.preventDefault();
      } else if (delta < 0 && activeProject > 0) {
        setActiveProject((prev) => prev - 1);
        event.preventDefault();
      }
    };

    const handleBodyScroll = (event) => {
      if (isPortfolioInView && activeProject < projects.length - 1) {
        event.preventDefault();
      }
    };

    window.addEventListener('wheel', handleScroll, { passive: false });
    window.addEventListener('scroll', handleBodyScroll, { passive: false });
    return () => {
      window.removeEventListener('wheel', handleScroll);
      window.removeEventListener('scroll', handleBodyScroll);
    };
  }, [isPortfolioInView, activeProject]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsPortfolioInView(entry.isIntersecting);
        });
      },
      { threshold: 0.5 }
    );

    if (portfolioRef.current) {
      observer.observe(portfolioRef.current);
    }

    return () => {
      if (portfolioRef.current) {
        observer.unobserve(portfolioRef.current);
      }
    };
  }, []);

  const { name, color, typography, type, galeria, url } = projects[activeProject];

  const handleClick = (index) => {
    setActiveProject(index);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <ArrowBackIosIcon className="slider-arrow slider-arrow-prev" onClick={(e) => e.stopPropagation()} />,
    nextArrow: <ArrowForwardIosIcon className="slider-arrow slider-arrow-next" onClick={(e) => e.stopPropagation()} />,
  };

  return (
    <section id="portafolio" className="portafolio" ref={portfolioRef}>
      <div className="container">
        <div className="row">
          <div className="contentIzq">
            <div className="title">
              <div className="circle"></div>
              <h2>Portafolio</h2>
            </div>
            <div className="slider">
              <ul>
                {projects.map((project, index) => (
                  <li
                    key={index}
                    className={activeProject === index ? 'active' : ''}
                    onClick={() => handleClick(index)}
                  >
                    {project.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="contentDrh" style={{ borderWidth: 1, borderColor: color[0],   }}>
            <div className="borderIdentificate" style={{ backgroundColor: '#fff', }}></div>
            <h4>{name}</h4>
            <div className="detail">
              <div>
                <h6>Color</h6>
                <div className="color">
                  {color.map((col, index) => (
                    <div key={index} style={{ backgroundColor: col, width: "20px", height: "20px", display: "inline-block", marginRight: "5px" }}></div>
                  ))}
                </div>
              </div>
              <div>
              <h6>Tipografía</h6>
                {typography.map((typo, index) => (
                  <p key={index}>{typo}</p>
                ))}
              </div>
              <div>
                <h6>Tipo de proyecto</h6>
              <p>{type}</p>
              </div>
            </div>
            <div className="contentImg">
              {galeria.map((image, index) => (
                <div key={index} className="boxImg" onClick={() => handleImageClick(image)}>
                  <img src={image} alt={`gallery ${index}`} />
                </div>
              ))}
            </div>
            <a href={url} target="_blank" rel="noopener noreferrer">
              <Button>
                Ver Sitio Web <ScreenShareIcon />
              </Button>
            </a>
          </div>
        </div>
      </div>
      {selectedImage && (
        <div className="modal" onClick={handleCloseModal}>
          <span className="close" onClick={handleCloseModal}>&times;</span>
          <div className="sliderContainer" onClick={(e) => e.stopPropagation()}>
            <Slider {...settings}>
              {galeria.map((image, index) => (
                <div key={index} className="modal-slide" onClick={(e) => e.stopPropagation()}>
                  <img src={image} alt={`gallery ${index}`} className="modal-content" />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      )}
    </section>
  );
}

export default Portafolio;
