import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Planes from './components/planes/Planes';
import Portafolio from './components/portafolio/Portafolio';
import DiseñoPersonalizado from './components/DiseñoPersonalizado';
import Administrable from './components/Administrable';
import Trabajo from './components/trabajo/Trabajo';
import CallToAction from './components/CallToAtion';
import Contacto from './components/Contacto';
import Nosotros from './components/Nosotros';
import WhatsappFlotante from './components/WhatsappFlotante';
import PrivacyPolicy from './Views/PrivacyPolicy';
import Blog from './Views/Blog';
import ArticleDetail from './Views/ArticleDetail';
import TodoIncluido from './components/TodoIncluido';
import './assets/styles/App.scss'; 
import PortafolioPage from './Views/PortafolioPage';

// Componente con Header y Footer (Layout global)
const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
      <WhatsappFlotante />
    </>
  );
};

function HomePage() {
  return (
    <>
      <Hero/>
      <Nosotros/>
      <DiseñoPersonalizado/>
      <Administrable/>
      <TodoIncluido/>
      <Portafolio/>
      <Planes/>
      <CallToAction/>
      <Trabajo/>
      <Contacto/>
      <WhatsappFlotante/>
    </>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Rutas con Layout global */}
          <Route  path="/"  element={
              <Layout>
                <HomePage />
              </Layout>
            }
          />
          <Route path="/portafolio" element={
            <Layout>
              <PortafolioPage/>
            </Layout>
          }

          />
          <Route path="/blog" element={
              <Layout>
                <Blog />
              </Layout>
            }
          />
          <Route path="blog/:id" element={
            <Layout>
              <ArticleDetail/>
            </Layout>
          }
          />
          <Route path="/privacy-policy" element={
              <Layout>
                <PrivacyPolicy />
              </Layout>
            }
          />

        </Routes>
      </div>
    </Router>
  );
}


export default App;
