import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios'; // Para hacer la petición al backend

function ArticleDetail() {
  const { id } = useParams(); // Obtener el ID del artículo desde la URL
  const [article, setArticle] = useState(null); // Estado para almacenar los detalles del artículo
  const [loading, setLoading] = useState(true); // Estado de carga

  useEffect(() => {

    const fetchArticle = async () => {
      try {
        const response = await axios.get(`/api/articles/${id}`); // Petición al backend con el ID
        setArticle(response.data); // Guardar los datos en el estado
        setLoading(false); // Dejar de cargar
      } catch (error) {
        console.error('Error fetching article details:', error);
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  if (loading) {
    return  (
      <>
      <section className="banner">
      <div className="bg-banner">
        <div className="container">
          <h1>Cargando...</h1>
        </div>
      </div>
    </section>
      </>
      );
  }

  if (!article) {
    return (
    <>
    <section className="banner">
    <div className="bg-banner">
      <div className="container">
        <h1>Ups.. :(</h1>
        <p>Artículo no encontrado </p>
      </div>
    </div>
  </section>
    </>
    );
  }

  return (
    <div>
      <h1>{article.title}</h1>
      <p>{article.content}</p>
      <span>{article.date}</span>
      <span>{article.category}</span>
    </div>
  );
}

export default ArticleDetail;
